import DataTable, { TableColumn } from "react-data-table-component";
import { NoResult } from "../Common/NoResult";
import "../../styles/dashboard.css";
import { Button, Modal, ModalHeader, Table } from "reactstrap";
import { jumpTo } from "../UrlModal";
import { useState } from "react";
import moment from "moment";
import Loader from "../Common/Loader";
import { fetchTopPlanDetail } from "../../api/market";
import { KWHPriceChart } from "../KWHPriceChart";
import { getKWHPrice } from "../../store/actions";
import { useDispatch } from "react-redux";

const customStyles = {
  headCells: {
    style: {
      backgroundColor: "#F2F2F2",
      paddingRight: 0,
    },
  },
};

const TxDataTable = ({ data, isHide }: { data: any; isHide: boolean }) => {
  const dispatch = useDispatch();
  const [isShowDetail, setIsShowDetail] = useState(false);
  const [loadingDetail, setLoadingDetail] = useState(false);
  const [planDetail, setPlanDetail] = useState<any>(undefined);

  const formattedData: any = [];

  data.forEach((entry: any) => {
    const utilityName = entry.utility_name;
    const priceData = entry.price_data;
    for (const [key, value] of Object.entries(priceData)) {
      if (typeof value === "object" && value !== null) {
        formattedData.push({
          ...value,
          utility_name: utilityName,
          kwh: key.replace(/\D/g, ""),
        });
      }
    }
  });

  const filteredData = isHide
    ? formattedData.filter(
        (item: any) =>
          item.market_rank !== 0 || JSON.stringify(item.your_plan) !== "[]"
      )
    : formattedData;

  const showDetail = (planId: string, price: string, createdTs: string) => {
    setIsShowDetail(true);
    setLoadingDetail(true);
    dispatch(getKWHPrice(true));

    fetchTopPlanDetail(
      planId,
      price,
      createdTs,
      `${moment().subtract(1, "months").format("YYYY-MM-DD")}&${moment().format(
        "YYYY-MM-DD"
      )}`
    ).then((res) => {
      setLoadingDetail(false);
      if (res && parseInt(res.status) === 1) {
        const { response } = res || {};

        let series: any[] = [];

        response?.rank_trend?.forEach((item: any) => {
          if (item?.rank !== 0) {
            series.push({ x: item.date, y: item.rank });
          } else {
            series.push({ x: item.date, y: null });
          }
        });

        setPlanDetail({
          chartData: [{ id: "Rank", data: series.filter((i: any) => i.y) }],
          planInfo: response?.plan,
        });
      }
    });
  };

  const closeDetail = () => {
    setPlanDetail(undefined);
    setIsShowDetail(false);
    dispatch(getKWHPrice(false));
  };

  const columns: TableColumn<never>[] = [
    {
      name: <span className="font-weight-bold fs-14">Utility</span>,
      selector: (row: any) => row.utility_name,
      width: "200px",
    },
    {
      name: <span className="font-weight-bold fs-14">Monthly kWh Usage</span>,
      selector: (row: any) => `${row.kwh} kWh`,
    },
    {
      name: <span className="font-weight-bold fs-14">Provider Count</span>,
      selector: (row: any) => row.provider_count,
    },
    {
      name: <span className="font-weight-bold fs-14">Market Plan Count (MKT) / My Plan Count (MY)</span>,
      selector: (row: any) => (
        <table>
          <tbody>
            <tr>
              <td width="35">MKT</td>
              <td>{row.plan_count}</td>
            </tr>
            <tr>
              <td width="35">MY</td>
              <td>{row.your_plan_count || "-"}</td>
            </tr>
          </tbody>
        </table>
      ),
      width: "150px",
    },
    {
      name: (
        <span className="font-weight-bold fs-14">
          Market Average Rate (MKT) / Lowest Rate (LST) / My Rate (MY)
        </span>
      ),
      selector: (row: any) => {
        const getBarColor = (rank: string) => {
          if (Number(rank) >= 0 && Number(rank) <= 10) return "low";
          if (Number(rank) >= 11 && Number(rank) <= 20) return "mid";
          return "high";
        };

        const maxValue = Math.max(
          Number(row.average_rate),
          Number(row.lowest_rate),
          Number(row.your_rate)
        );

        return (
          <table width="250">
            <tbody>
              <tr>
                <td width="35">AVG</td>
                <td width="65">{row.average_rate}¢</td>
                <td>
                  <div
                    className={`price-bar`}
                    style={{
                      width: (Number(row.average_rate) / maxValue) * 100,
                    }}
                  />
                </td>
              </tr>
              <tr>
                <td width="35">LST</td>
                <td width="65">{row.lowest_rate}¢</td>
                <td>
                  <div
                    className={`price-bar`}
                    style={{
                      width: (Number(row.lowest_rate) / maxValue) * 100,
                    }}
                  />
                </td>
              </tr>
              <tr>
                <td width="35">MY</td>
                <td width="65">{row.your_rate ? `${row.your_rate}¢` : "-"}</td>
                <td>
                  <div
                    className={`price-bar ${getBarColor(row.market_rank)}`}
                    style={{ width: (Number(row.your_rate) / maxValue) * 100 }}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        );
      },
      width: "250px",
    },
    {
      name: <span className="font-weight-bold fs-14">Market Rank</span>,
      selector: (row: any) => row.market_rank || "-",
    },
    {
      name: <span className="font-weight-bold fs-14">Lowest Rate Plan</span>,
      selector: (row: any) => {
        if (JSON.stringify(row?.rank1_plan) !== "[]") {
          return (
            <div className="py-2">
              <div>{row?.rank1_plan?.provider_name}</div>
              <div
                className="text-muted fs-12 mb-1"
                style={{ whiteSpace: "normal" }}
              >
                {row?.rank1_plan?.plan_name}
              </div>
              <div>
                Term {row?.rank1_plan?.contract_term}{" "}
                <span className="text-muted ms-1 me-1 fs-12">|</span>{" "}
                {row?.rank1_plan?.price}¢
              </div>
              <div className="d-flex gap-2 mt-2">
                <Button
                  color="secondary"
                  outline
                  className="btn btn-sm"
                  onClick={() =>
                    showDetail(
                      row?.rank1_plan?.plan_id,
                      row?.kwh,
                      row?.rank1_plan?.created_ts
                    )
                  }
                >
                  Detail
                </Button>
                <Button
                  onClick={() => {
                    jumpTo(row?.rank1_plan?.plan_id, row?.rank1_plan?.pd_id);
                  }}
                  outline
                  className="btn btn-sm"
                >
                  EFL
                </Button>
              </div>
            </div>
          );
        } else {
          return <div className="no-plan-col">No Plan</div>;
        }
      },
      width: "250px",
    },
    {
      name: <span className="font-weight-bold fs-14">My Plan</span>,
      selector: (row: any) => {
        if (JSON.stringify(row?.your_plan) !== "[]") {
          return (
            <div className="py-2">
              <div>{row?.your_plan?.provider_name}</div>
              <div
                className="text-muted fs-12 mb-1"
                style={{ whiteSpace: "normal" }}
              >
                {row?.your_plan?.plan_name}
              </div>
              <div>
                Term {row?.your_plan?.contract_term}{" "}
                <span className="text-muted ms-1 me-1 fs-12">|</span>{" "}
                {row?.your_plan?.price}¢
              </div>
              <div className="d-flex gap-2 mt-2">
                <Button
                  color="secondary"
                  outline
                  className="btn btn-sm"
                  onClick={() =>
                    showDetail(
                      row?.your_plan?.plan_id,
                      row?.kwh,
                      row?.your_plan?.created_ts
                    )
                  }
                >
                  Detail
                </Button>
                <Button
                  onClick={() => {
                    jumpTo(row?.your_plan?.plan_id, row?.your_plan?.pd_id);
                  }}
                  outline
                  className="btn btn-sm"
                >
                  EFL
                </Button>
              </div>
            </div>
          );
        } else {
          return <div className="no-plan-col">No Plan</div>;
        }
      },
      width: "250px",
    },
  ];

  return (
    <>
      {filteredData.length > 0 ? (
        <DataTable
          columns={columns}
          data={filteredData}
          paginationPerPage={10}
          customStyles={customStyles}
          pagination={false}
        />
      ) : (
        <NoResult />
      )}
      <Modal size="lg" isOpen={isShowDetail} toggle={closeDetail} centered>
        <ModalHeader toggle={closeDetail}>
          My Plan Detail
          <span className="fs-14 text-muted ms-3">
            {`${moment()
              .subtract(1, "months")
              .format("YYYY-MM-DD")} - ${moment().format("YYYY-MM-DD")}`}
          </span>
        </ModalHeader>
        {loadingDetail ? (
          <Loader />
        ) : planDetail ? (
          <div className="modal-body" style={{ overflow: "hidden" }}>
            <>
              <Table className="align-middle table-fixed mb-0">
                <thead>
                  <tr>
                    <th scope="col">REP Name</th>
                    <th scope="col">TDU Name</th>
                    <th scope="col">Term</th>
                    <th scope="col">Base Rate</th>
                    <th scope="col">Plan Source</th>
                    <th scope="col">Cancellation Fee</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{planDetail.planInfo.provider_name}</td>
                    <td>{planDetail.planInfo.utility_name}</td>
                    <td>{planDetail.planInfo.contract_term}</td>
                    <td>
                      {planDetail.planInfo.base_rate}
                      {planDetail.planInfo.base_rate ? "¢" : ""}
                    </td>
                    <td>{planDetail.planInfo.source || ""}</td>
                    <td>{planDetail.planInfo.cancel_fee}</td>
                  </tr>
                </tbody>
              </Table>
            </>
          </div>
        ) : (
          <NoResult />
        )}
        {planDetail?.planInfo && (
          <KWHPriceChart
            plan_id={planDetail.planInfo.plan_id}
            pd_id={planDetail.planInfo.pd_id}
          />
        )}
      </Modal>
    </>
  );
};

export default TxDataTable;
