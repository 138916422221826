import { createContext, useEffect } from "react";
import "./assets/scss/themes.scss";
import "react-tooltip/dist/react-tooltip.css";
import Route from "./Routes/index";
import { fetchEflDoc } from "./api/myplan";
import { errornotify } from "./Components/Message";
import { ToastContainer } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {
  eflUrl,
  eflUrlDisplay,
  fetchStateRequest,
  fetchUtilityRequest,
} from "./store/actions";
import { selectIsLoading, selectLocation } from "./store/location/selector";
import { Hub } from "aws-amplify";
import Loader from "./Components/Common/Loader";
import { selectUtility } from "./store/utility/selector";
import { fetchMyProviderRequest } from "./store/myProvider/action";
// import { UrlModal } from "./Components/UrlModal";

export const Utility = createContext<any>({});

export default function App() {
  const dispatch = useDispatch();
  const currentLocation = useSelector(selectLocation);
  const currentUtility = useSelector(selectUtility);
  const isLoadingLocation = useSelector(selectIsLoading);

  useEffect(() => {
    dispatch(fetchStateRequest());
  }, [dispatch]);

  useEffect(() => {
    if (!currentLocation) return;
    dispatch(fetchUtilityRequest());
  }, [dispatch, currentLocation]);

  useEffect(() => {
    if (!currentLocation || !currentUtility) return;
    dispatch(fetchMyProviderRequest());
  }, [dispatch, currentLocation, currentUtility]);

  useEffect(() => {
    const listener = ({ payload }: { payload: any }) => {
      const { event } = payload;
      switch (event) {
        case "signIn":
        case "signOut":
          console.log("signin-signout");
          dispatch(fetchStateRequest());
          dispatch(fetchMyProviderRequest());
          break;
      }
    };

    Hub.listen("auth", listener);

    return () => {
      Hub.remove("auth", listener);
    };
  }, []);

  const getEflDoc = async (plan_id: any, pd_id: any) => {
    const res = await fetchEflDoc(plan_id, pd_id);
    if (res.status === 1) {
      dispatch(eflUrl(res.response));
      dispatch(eflUrlDisplay(true));
    } else {
      errornotify(res.message);
      return;
    }
  };

  return (
    <div className="App">
      <Utility.Provider value={{ getEflDoc }}>
        {!isLoadingLocation ? (
          <Route />
        ) : (
          <div
            style={{
              height: "100vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Loader />
          </div>
        )}
      </Utility.Provider>
      <ToastContainer />
      {/* <UrlModal/> */}
    </div>
  );
}
