import { useEffect, useState } from "react";
import { prompts } from "../../common/prompts";
import { fetchAiSummaryResult } from "../../api/ai";
import showdown from "showdown";
import { Card, CardBody, CardHeader, Spinner } from "reactstrap";
import "../../styles/ai-summary.css";
import { useSelector } from "react-redux";
import { selectMyProvider } from "../../store/myProvider/selector";
import { Chip } from "@mui/material";

const Summary = ({
  data,
  section = "common",
}: {
  data: string;
  section?: string;
}) => {
  const prompt = prompts[section] || "";
  const [summaryResult, setSummaryResult] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const myProvider = useSelector(selectMyProvider);

  const converter = new showdown.Converter();

  useEffect(() => {
    const getResult = () => {
      // console.log(data);
      // console.log(prompt);
      setLoading(true);
      const finalPrompt = prompt.replaceAll(
        "[Rep name]",
        myProvider.provider_name
      );
      fetchAiSummaryResult(data, finalPrompt).then((res) => {
        setLoading(false);

        if (res) {
          setSummaryResult(res);
        } else {
          setSummaryResult("");
        }
      });
    };

    if (data && prompt && myProvider) {
      getResult();
    }
  }, [data, prompt, myProvider]);

  if (!data || !prompt) return null;

  return (
    <Card>
      <CardHeader>
        <h5 className="mb-0">
          ✨ Ai Analysis{" "}
          <Chip
            label="Beta"
            size="small"
            sx={{ fontWeight: 400, fontSize: 13 }}
          />
        </h5>
      </CardHeader>
      <CardBody>
        {loading ? (
          <div className="ai-summary-thinking mt-2">
            <Spinner
              size="sm"
              type="grow"
              color="secondary"
              style={{ flexShrink: 0 }}
            >
              Loading...
            </Spinner>
            <span>AI is analyzing data, summary will be ready shortly…</span>
          </div>
        ) : summaryResult ? (
          (() => {
            const convertedValue = converter.makeHtml(summaryResult);

            if (convertedValue) {
              return (
                <div dangerouslySetInnerHTML={{ __html: convertedValue }} />
              );
            } else {
              return null;
            }
          })()
        ) : null}
      </CardBody>
    </Card>
  );
};

export default Summary;
